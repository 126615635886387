import jsPDF from 'jspdf'
import {
    getScheduleDates,
    getSchedules,
    getSingleMatchByUuid,
    getSingleParticipant,
    getSinglePlayer,
    getSingleReferee,
    getTournamentsFields,
    getTournamentsReferees,
} from '../../../../../../lib/api'
import {
    formatDate,
    formatDateWithDay,
    formatTime,
    handleSchedulePlayersList,
    handleSchedulePrintText,
} from '../../../../../../lib/helpers'
import { font } from '../../font'

const fetchTournamentAdminReferee = async (tournamentUuid) => {
    const refereesResponse = await getTournamentsReferees(tournamentUuid, {
        admin_referee: true,
    })

    if (refereesResponse && refereesResponse?.length > 0) {
        let refereeInfo = await getSingleReferee(
            refereesResponse[0]?.attributes?.referee_uuid
        )

        if (!refereeInfo) return null
        return refereeInfo
    } else {
        return null
    }
}

const fetchTournamentsFields = async (tournamentUuid) => {
    const fieldsData = await getTournamentsFields(tournamentUuid)

    if (!fieldsData) return []
    return fieldsData
}

const fetchTournamentDates = async (tournamentUuid) => {
    const scheduleDates = await getScheduleDates(tournamentUuid)

    if (scheduleDates?.length > 0) {
        return scheduleDates
    } else {
        return []
    }
}

const fetchSchedules = async (fieldUuid, scheduleDate) => {
    const schedulesData = await getSchedules(fieldUuid, scheduleDate)

    if (!schedulesData) return null
    return schedulesData
}

const addFooters = (doc, marginLeft) => {
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(
            `Datum i vreme štampanja: ${formatDate(
                new Date()
            )} ${new Date().toLocaleTimeString()}`,
            doc.internal.pageSize.width - marginLeft,
            doc.internal.pageSize.height - 10,
            { align: 'right' }
        )
    }
}

const createDoc = async (data, orientation, unit, size, font) => {
    const { competitionProfileData } = data
    let adminRefereeInfo = await fetchTournamentAdminReferee(
        competitionProfileData?.attributes?.uuid
    )

    let fields = await fetchTournamentsFields(
        competitionProfileData?.attributes?.uuid
    )

    let scheduleDates = await fetchTournamentDates(
        competitionProfileData?.attributes?.uuid
    )

    let schedules = Array(scheduleDates?.length)
        .fill()
        .map(() => {
            return Array(fields.length)
                .fill()
                .map(() => [])
        })

    for (const [index, schedule] of scheduleDates.entries()) {
        for (const [fieldIndex, field] of fields.entries()) {
            let schedulesInfo = await fetchSchedules(
                field?.attributes?.uuid,
                schedule?.attributes?.schedule_date
            )

            if (schedulesInfo) {
                for (const singleSchedule of schedulesInfo) {
                    const matchData = await getSingleMatchByUuid(
                        singleSchedule?.attributes?.match_uuid
                    )

                    let matchesArray = [matchData]

                    let secondMatchData = null
                    if (singleSchedule?.attributes?.second_match_uuid) {
                        secondMatchData = await getSingleMatchByUuid(
                            singleSchedule?.attributes?.second_match_uuid
                        )

                        matchesArray.push(secondMatchData)
                    }

                    matchesArray = handleSchedulePlayersList(matchesArray)

                    const players = []
                    for (const match of matchesArray) {
                        const [participant_1, participant_2] =
                            await Promise.all([
                                match?.attributes?.side_1_uuid
                                    ? getSingleParticipant(
                                          match.attributes.side_1_uuid
                                      )
                                    : null,
                                match?.attributes?.side_2_uuid
                                    ? getSingleParticipant(
                                          match.attributes.side_2_uuid
                                      )
                                    : null,
                            ])

                        const [
                            side_1_player_1,
                            side_1_player_2,
                            side_2_player_1,
                            side_2_player_2,
                        ] = await Promise.all([
                            participant_1?.attributes?.player_1_uuid
                                ? getSinglePlayer(
                                      participant_1.attributes.player_1_uuid
                                  )
                                : null,
                            participant_1?.attributes?.player_2_uuid
                                ? getSinglePlayer(
                                      participant_1.attributes.player_2_uuid
                                  )
                                : null,
                            participant_2?.attributes?.player_1_uuid
                                ? getSinglePlayer(
                                      participant_2.attributes.player_1_uuid
                                  )
                                : null,
                            participant_2?.attributes?.player_2_uuid
                                ? getSinglePlayer(
                                      participant_2.attributes.player_2_uuid
                                  )
                                : null,
                        ])

                        players.push({
                            side_1_player_1,
                            side_1_player_2,
                            side_2_player_1,
                            side_2_player_2,
                        })
                    }

                    let roundNumber = matchData?.attributes?.round_number
                    if (secondMatchData) {
                        roundNumber = roundNumber + 1
                    }

                    let scheduleData = {
                        ...singleSchedule,
                        attributes: {
                            ...singleSchedule.attributes,
                            round_number: roundNumber,
                            is_double: matchData?.attributes?.is_double,
                            gender: matchData?.attributes?.gender?.gender_name,
                            loser_participant_uuid:
                                matchData?.attributes?.loser_participant_uuid,
                            players,
                        },
                    }
                    schedules[index][fieldIndex].push(scheduleData)
                }
            }
        }
    }

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(15)

    // Za svaki datum
    for (let i = 0; i < schedules.length; i++) {
        const matchesByField = schedules[i]

        // Postavljanje zaglavlja tablice za taj datum
        // doc.text(`Datum: ${matchesByField[0][0].attributes.match_date}`, 10, 10);

        const title = `${competitionProfileData?.attributes?.name}`
        doc.text(title, marginLeft, 40)

        const competitionDay = matchesByField[0][0]?.attributes?.match_date
        const textWidth = doc.getTextDimensions(
            `Takmičarski dan: ${formatDateWithDay(competitionDay)}`,
            { fontSize: 15 }
        ).w
        const pageWidth = doc.internal.pageSize.getWidth()
        const textX = pageWidth - textWidth - marginLeft
        doc.text(
            `Takmičarski dan: ${formatDateWithDay(competitionDay)}`,
            textX,
            40
        )

        let generalInfo = [
            [
                formatDate(competitionProfileData?.attributes?.start_date) ??
                    'Nema informacije',
                competitionProfileData?.attributes?.club_organizer?.attributes
                    ?.name ?? 'Nema informacije',
                competitionProfileData?.attributes?.tournament_category
                    ?.tournament_category_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.tournament_category?.tournament_category_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.tournament_category
                              ?.tournament_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                competitionProfileData?.attributes?.players_competition
                    ?.players_competition_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.players_competition?.players_competition_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.players_competition
                              ?.players_competition_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                adminRefereeInfo
                    ? `${
                          adminRefereeInfo?.attributes?.person_info
                              ?.given_name ?? ''
                      } ${
                          adminRefereeInfo?.attributes?.person_info
                              ?.family_name ?? ''
                      }`
                    : 'Nema informacije',
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [
                [
                    'Datum',
                    'Grad, klub',
                    'Kategorija',
                    'Konkurencija',
                    'Vrhovni sudija',
                ],
            ],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(generalInfoContent)

        const tableData = []

        // Za svaki meč
        for (
            let k = 0;
            k <
            matchesByField.reduce((acc, curr) => Math.max(acc, curr.length), 0);
            k++
        ) {
            const rowData = [`${k + 1}. meč`, '', '', '']

            // Postavljanje informacija o mečevima za svaki teren
            for (let j = 0; j < matchesByField.length; j++) {
                const matches = matchesByField[j]

                if (matches[k]) {
                    const match = matches[k]

                    let value = handleSchedulePrintText(
                        match?.attributes?.players
                    )

                    rowData[j + 1] = `${
                        match?.attributes?.match_time ? 'Početak: ' : ''
                    }${
                        match?.attributes?.match_time
                            ? match?.attributes?.time_description ===
                              'Posle odmora'
                                ? match?.attributes?.time_description +
                                  '\nNe pre' +
                                  ' ' +
                                  formatTime(match?.attributes?.match_time)
                                : match?.attributes?.time_description +
                                  ' ' +
                                  formatTime(match?.attributes?.match_time)
                            : match?.attributes?.time_description
                    }\n\n${
                        match?.attributes?.first_free_field
                            ? 'Prvi slobodan teren\n\n'
                            : ''
                    }${match?.attributes?.gender}${` `}${
                        match?.attributes?.is_double ? 'Dubl' : 'Singl'
                    }${' '}${
                        match?.attributes?.round_number
                            ? match?.attributes?.round_number + '.kolo'
                            : ''
                    }\n\n${value}`
                }
            }

            // Dodavanje reda u tabelu
            tableData.push(rowData)
        }

        // Dodavanje tablice mečeva za taj datum
        doc.autoTable({
            head: [
                ['', ...fields?.map((field) => field?.attributes?.field_name)],
            ],
            body: tableData,
            startY: doc.lastAutoTable.finalY,
            styles: {
                font: 'WorkSans',
                halign: 'center',
                fontSize: 7,
            },
            headStyles: {
                lineWidth: 0.2,
                valign: 'middle',
            },
            columnStyles: {
                0: { cellWidth: 50, valign: 'middle', lineWidth: 0.2 },
                ...[...Array(fields.length).keys()].reduce((acc, index) => {
                    acc[index + 1] = {
                        cellWidth:
                            (doc.internal.pageSize.width -
                                50 -
                                marginLeft -
                                marginLeft) /
                            fields.length,
                        lineWidth: 0.2,
                    }
                    return acc
                }, {}),
            },
            rowPageBreak: 'avoid',
        })

        // Dodavanje nove stranice za svaki novi datum, osim za posljednji datum
        if (i !== schedules.length - 1) {
            doc.addPage()
        }
    }

    addFooters(doc, marginLeft)

    return doc
}

export const handleExportScheduleTabPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'landscape' // portrait or landscape

    const { competitionProfileData, setIsLoading } = data

    setIsLoading(true)

    const doc = await createDoc(data, orientation, unit, size, font)

    setIsLoading(false)

    doc.save(
        `Raspored-${
            competitionProfileData?.attributes?.name
        }-${new Date().toLocaleTimeString()}.pdf`
    )
}

export const handlePrintScheduleTabPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'landscape' // portrait or landscape

    const { setIsLoading } = data

    setIsLoading(true)

    const doc = await createDoc(data, orientation, unit, size, font)

    setIsLoading(false)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}

import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AddMatchModal from '../../containers/Admin/Competitions/CompetitionProfile/Tabs/Schedule/addMatchModal'
import {
    getSingleClubWithQuery,
    getSingleMatchByUuid,
    getSingleParticipant,
    getSinglePlayer,
    getSingleSchedule,
    removeSchedule,
} from '../../lib/api'
import {
    formatStringWithStrongText,
    formatTime,
    handleSchedulePlayersList,
    handleSchedulePrintText,
} from '../../lib/helpers'
import Button from '../Button'
import ConfirmationModal from '../ConfirmationModal'
import CardParticipantSection from './cardParticipant'
import './styles.scss'

const TournamentScheduleCard = ({
    scheduleUuid,
    className,
    handleShowTournamentsField,
    hideButton = false,
    schedules = false,
    tournament = false,
}) => {
    const [schedule, setSchedule] = useState()
    const [showDeleteMatchModal, setShowDeleteMatchModal] = useState(false)
    const [showAddMatchModal, setShowAddMatchModal] = useState(false)

    const handleGetSingleSchedule = async () => {
        if (scheduleUuid) {
            const scheduleData = await getSingleSchedule(scheduleUuid)
            if (scheduleData) {
                const matchData = await getSingleMatchByUuid(
                    scheduleData?.attributes?.match_uuid
                )

                let matchesArray = [matchData]

                let secondMatchData = null
                if (scheduleData?.attributes?.second_match_uuid) {
                    secondMatchData = await getSingleMatchByUuid(
                        scheduleData?.attributes?.second_match_uuid
                    )

                    matchesArray.push(secondMatchData)
                }

                matchesArray = handleSchedulePlayersList(matchesArray)

                const players = []
                for (const match of matchesArray) {
                    const [participant_1, participant_2] = await Promise.all([
                        match?.attributes?.side_1_uuid
                            ? getSingleParticipant(match.attributes.side_1_uuid)
                            : null,
                        match?.attributes?.side_2_uuid
                            ? getSingleParticipant(match.attributes.side_2_uuid)
                            : null,
                    ])

                    const [
                        side_1_player_1,
                        side_1_player_2,
                        side_2_player_1,
                        side_2_player_2,
                    ] = await Promise.all([
                        participant_1?.attributes?.player_1_uuid
                            ? getSinglePlayer(
                                  participant_1.attributes.player_1_uuid
                              )
                            : null,
                        participant_1?.attributes?.player_2_uuid
                            ? getSinglePlayer(
                                  participant_1.attributes.player_2_uuid
                              )
                            : null,
                        participant_2?.attributes?.player_1_uuid
                            ? getSinglePlayer(
                                  participant_2.attributes.player_1_uuid
                              )
                            : null,
                        participant_2?.attributes?.player_2_uuid
                            ? getSinglePlayer(
                                  participant_2.attributes.player_2_uuid
                              )
                            : null,
                    ])

                    players.push({
                        side_1_player_1,
                        side_1_player_2,
                        side_2_player_1,
                        side_2_player_2,
                    })
                }

                let clubHost

                if (scheduleData?.attributes?.club_host_uuid) {
                    clubHost = await getSingleClubWithQuery(
                        scheduleData?.attributes?.club_host_uuid,
                        'fields[0]=name&fields[1]=short_name&fields[2]=uuid'
                    )
                } else {
                    clubHost = tournament?.attributes?.club_organizer
                }

                let roundNumber = matchData?.attributes?.round_number
                if (secondMatchData) {
                    roundNumber = roundNumber + 1
                }

                setSchedule({
                    ...scheduleData,
                    attributes: {
                        ...scheduleData.attributes,
                        round_number: roundNumber,
                        is_double: matchData?.attributes?.is_double,
                        gender: matchData?.attributes?.gender?.gender_name,
                        loser_participant_uuid:
                            matchData?.attributes?.loser_participant_uuid,
                        players,
                        club_host_info: clubHost,
                    },
                })
            }
        }
    }

    const handleRemoveSchedule = async () => {
        const removedSchedule = await removeSchedule(scheduleUuid)

        if (removedSchedule) {
            handleShowTournamentsField()
        }
    }

    useEffect(() => {
        handleGetSingleSchedule()
    }, [schedules])

    return (
        <>
            <Card
                className={`finish-result-card px-0 ${className} ${
                    schedule?.attributes?.match_end_time
                        ? 'finished-match-result-card'
                        : ''
                }`}
                style={{ borderRadius: '10px' }}
            >
                <Card.Body>
                    <Row className="row-segment d-flex flex-column px-0 mb-2">
                        <Col className="d-flex align-items-center mb-2">
                            <p className="pt-1">
                                {schedule?.attributes?.time_description}{' '}
                                {formatTime(schedule?.attributes?.match_time)}
                            </p>
                        </Col>
                        <Col className="d-flex text-end">
                            <p className="small-text">
                                {schedule?.attributes?.gender}{' '}
                                {schedule?.attributes?.is_double
                                    ? 'Dubl'
                                    : 'Singl'}{' '}
                                {schedule?.attributes?.round_number
                                    ? schedule?.attributes?.round_number +
                                      '.kolo'
                                    : ''}
                            </p>
                        </Col>

                        {schedule?.attributes?.first_free_field === true && (
                            <Col xs={12} className="mt-2">
                                <p className="small-text">
                                    Igra se na prvom slobodnom terenu
                                </p>
                            </Col>
                        )}

                        <Col xs={12} className="mt-2">
                            <div className="d-flex align-items-center justify-content-start w-100">
                                <p className="me-1">Igra se u klubu:</p>
                                <Link
                                    to={`/klubovi/${schedule?.attributes?.club_host_info?.attributes?.uuid}`}
                                    className="link"
                                >
                                    {
                                        schedule?.attributes?.club_host_info
                                            ?.attributes?.name
                                    }
                                </Link>
                            </div>
                        </Col>

                        {schedule?.attributes?.match_end_time && (
                            <Col className="d-flex align-items-center mt-2">
                                <p className="pt-1">
                                    Kraj meča:{' '}
                                    {formatTime(
                                        schedule?.attributes?.match_end_time
                                    )}
                                </p>
                            </Col>
                        )}
                    </Row>
                    <CardParticipantSection
                        participantData={schedule?.attributes?.players}
                        firstPart={true}
                    />
                    <CardParticipantSection
                        participantData={schedule?.attributes?.players}
                        firstPart={false}
                    />
                    {!hideButton && (
                        <Row>
                            <Col className="px-0 d-flex">
                                <Button
                                    type="text"
                                    className="w-100 card-left-button"
                                    onClick={() => {
                                        setShowAddMatchModal(true)
                                    }}
                                >
                                    Izmeni
                                </Button>
                                <Button
                                    type="text"
                                    className="w-100 card-right-button"
                                    onClick={() =>
                                        setShowDeleteMatchModal(true)
                                    }
                                >
                                    Ukloni meč
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>

            <AddMatchModal
                show={showAddMatchModal}
                onHide={() => setShowAddMatchModal(false)}
                tournament={tournament}
                selectedFieldUuid={{
                    field_uuid: schedule?.attributes?.field_uuid,
                }}
                selectedScheduleDate={{
                    match_date: schedule?.attributes?.match_date,
                }}
                handleShowTournamentsField={handleShowTournamentsField}
                editForm={{
                    uuid: schedule?.attributes?.uuid,
                    match_uuid: schedule?.attributes?.match_uuid,
                    match_time: schedule?.attributes?.match_time,
                    time_description: schedule?.attributes?.time_description,
                    first_free_field: schedule?.attributes?.first_free_field,
                    club_host_uuid: schedule?.attributes?.club_host_uuid,
                    match_end_time: schedule?.attributes?.match_end_time,
                }}
                players={schedule?.attributes?.players}
                clubOrganizer={tournament?.attributes?.club_organizer}
            />

            <ConfirmationModal
                modalText={formatStringWithStrongText(
                    `Jeste li sigurni da želite ukloniti meč "${handleSchedulePrintText(
                        schedule?.attributes?.players
                    )}" ?`
                )}
                show={showDeleteMatchModal}
                onClick={() => {
                    handleRemoveSchedule(schedule?.id)
                    setShowDeleteMatchModal(false)
                }}
                onHide={() => {
                    setShowDeleteMatchModal(false)
                }}
            />
        </>
    )
}

export default TournamentScheduleCard

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../components/Button'
import {
    getMatchParticipants,
    getMatchPenalties,
    getSingleMatch,
    getSinglePlayerWithQuery,
} from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import CardParticipantSection from './cardParticipant'
import CardResultSection from './cardResultSection'
import ChangeMatchResultModal from './changeMatchResultModal'
import CreateMatchModal from './createMatchModal'
import DeleteMatchModal from './deleteMatchModal'
import MatchPenaltiesModal from './matchPenaltiesModal'
import './styles.scss'

const TournamentMatchCard = ({
    matchDetails,
    className,
    onDelete = false,
    onChange = false,
    isDeleteEnabled = true,
    createNextMatch = false,
    handleShowSlider,
    isFinal = false,
}) => {
    const { user, setSgpDataForPDF } = useAppContext()
    const [showCreateMatchModal, setShowCreateMatchModal] = useState(false)
    const [showChangeMatchResultModal, setShowChangeMatchResultModal] =
        useState(false)
    const [showDeleteMatchModal, setShowDeleteMatchModal] = useState(false)
    const [showMatchPenaltiesModal, setShowMatchPenaltiesModal] =
        useState(false)
    const [sideOneData, setSideOneData] = useState()
    const [sideTwoData, setSideTwoData] = useState()
    const [matchData, setMatchData] = useState()
    const [matchPenalties, setMatchPenalties] = useState([])

    const fetchPlayerData = async (uuid) => {
        if (!uuid) return null
        let query = `populate[0]=person_info.picture`
        return await getSinglePlayerWithQuery(uuid, query)
    }

    const handleShowOrdinalNumber = (isSideOne = true, club_match_uuid) => {
        if (
            (matchDetails?.draw_type?.draw_type_name === 'Glavni turnir' ||
                matchDetails?.draw_type?.draw_type_name === 'Kvalifikacije' ||
                matchDetails?.draw_type?.draw_type_name === 'Utešni turnir') &&
            matchDetails?.pair_number &&
            matchDetails?.round_number === 1 &&
            !club_match_uuid
        ) {
            if (isSideOne) {
                return matchDetails?.pair_number - 1 + matchDetails?.pair_number
            } else {
                return matchDetails?.pair_number * 2
            }
        } else return ''
    }

    const handleGetSingleMatch = async () => {
        const { round_number, tournament_uuid } = matchDetails || {}

        try {
            if (round_number && tournament_uuid) {
                let match
                if (matchDetails?.match?.attributes?.uuid) {
                    match = matchDetails?.match
                } else {
                    match = await getSingleMatch(matchDetails)
                }

                if (match) {
                    const {
                        side_1_uuid,
                        side_2_uuid,
                        side_1_result,
                        side_2_result,
                        uuid,
                        loser_participant_uuid,
                    } = match?.attributes || {}

                    // const [matchPenaltiesData, matchParticipants] =
                    //     await Promise.all([
                    //         getMatchPenalties(uuid),
                    //         getMatchParticipants({
                    //             side_1_uuid,
                    //             side_2_uuid,
                    //         }),
                    //     ])

                    const matchPenaltiesData = await getMatchPenalties(uuid)
                    const matchParticipants = await getMatchParticipants({
                        side_1_uuid,
                        side_2_uuid,
                    })

                    if (matchParticipants) {
                        const participant_1 = matchParticipants.find(
                            (participant) =>
                                participant?.attributes?.uuid === side_1_uuid
                        )
                        const participant_2 = matchParticipants.find(
                            (participant) =>
                                participant?.attributes?.uuid === side_2_uuid
                        )
                        const [
                            side_1_player_1,
                            side_1_player_2,
                            side_2_player_1,
                            side_2_player_2,
                        ] = await Promise.all([
                            fetchPlayerData(
                                participant_1?.attributes?.player_1_uuid
                            ),
                            fetchPlayerData(
                                participant_1?.attributes?.player_2_uuid
                            ),
                            fetchPlayerData(
                                participant_2?.attributes?.player_1_uuid
                            ),
                            fetchPlayerData(
                                participant_2?.attributes?.player_2_uuid
                            ),
                        ])

                        setSideOneData({
                            participant_uuid: side_1_uuid,
                            player_1: side_1_player_1,
                            player_2: side_1_player_2,
                            result: side_1_result,
                            ordinalNumber: handleShowOrdinalNumber(
                                true,
                                match?.attributes?.club_match_uuid || null
                            ),
                        })
                        setSideTwoData({
                            participant_uuid: side_2_uuid,
                            player_1: side_2_player_1,
                            player_2: side_2_player_2,
                            result: side_2_result,
                            ordinalNumber: handleShowOrdinalNumber(
                                false,
                                match?.attributes?.club_match_uuid || null
                            ),
                        })

                        setMatchData({
                            ...matchDetails,
                            uuid: uuid,
                            loser_participant_uuid: loser_participant_uuid,
                        })
                        setMatchPenalties(matchPenaltiesData)

                        setSgpDataForPDF((prev) => {
                            // Deep copy the previous state to ensure nested objects are cloned
                            const newState = JSON.parse(JSON.stringify(prev)) // Note: This may not work if you have functions, undefined, or circular references in your object

                            // Find the index of the round object by round_number
                            const roundIndex = newState.groupMatches.findIndex(
                                (r) =>
                                    r.round_number ===
                                    matchDetails?.round_number
                            )

                            if (roundIndex > -1) {
                                // Find the index of the match within the round by uuid
                                const matchIndex = newState.groupMatches[
                                    roundIndex
                                ].matches.findIndex(
                                    (m) =>
                                        m.attributes?.uuid ===
                                        matchDetails?.match?.attributes?.uuid
                                )

                                if (matchIndex > -1) {
                                    // Update the existing match data
                                    newState.groupMatches[roundIndex].matches[
                                        matchIndex
                                    ] = {
                                        ...newState.groupMatches[roundIndex]
                                            .matches[matchIndex],
                                        attributes: {
                                            ...newState.groupMatches[roundIndex]
                                                .matches[matchIndex].attributes,
                                            sideOneData: {
                                                participant_uuid: side_1_uuid,
                                                player_1: side_1_player_1,
                                                player_2: side_1_player_2,
                                                result: side_1_result,
                                                ordinalNumber:
                                                    handleShowOrdinalNumber(
                                                        true,
                                                        match?.attributes
                                                            ?.club_match_uuid ||
                                                            null
                                                    ),
                                            },
                                            sideTwoData: {
                                                participant_uuid: side_2_uuid,
                                                player_1: side_2_player_1,
                                                player_2: side_2_player_2,
                                                result: side_2_result,
                                                ordinalNumber:
                                                    handleShowOrdinalNumber(
                                                        false,
                                                        match?.attributes
                                                            ?.club_match_uuid ||
                                                            null
                                                    ),
                                            },
                                        },
                                        // Add additional match details here as needed
                                    }
                                } else {
                                    // Optionally, handle the case where the match is not found
                                    // You can push a new match object into the round.matches array
                                }
                            } else {
                                // Optionally, handle the case where the round is not found
                                // You can add a new round object to the newState.groupMatches array
                            }

                            return newState
                        })
                    }
                } else {
                    setSideOneData({
                        ordinalNumber: handleShowOrdinalNumber(true),
                    })
                    setSideTwoData({
                        ordinalNumber: handleShowOrdinalNumber(false),
                    })

                    setMatchData({
                        ...matchDetails,
                    })
                }
            }
        } catch (error) {
            console.error('Greška:', error)
        }
    }

    useEffect(() => {
        handleGetSingleMatch()
    }, [
        matchDetails?.pair_number,
        matchDetails?.round_number,
        matchDetails?.tournament_uuid,
        matchDetails?.is_double,
        matchDetails?.gender?.gender_name,
        matchDetails?.qualifying_section_number,
        matchDetails?.draw_type?.draw_type_name,
        matchDetails?.subtournament_name,
        matchDetails?.match?.attributes?.uuid,
        matchDetails?.match?.attributes?.loser_participant_uuid,
        matchDetails?.match?.attributes?.qualifying_section_number,
        matchDetails?.match?.attributes?.pair_number,
        matchDetails?.match?.attributes?.round_number,
        matchDetails?.match?.attributes?.side_1_result,
        matchDetails?.match?.attributes?.side_2_result,
        handleShowSlider,
    ])
    return (
        <Row className={`tournament-match-card ${className}`}>
            <Col>
                <Row>
                    <Col>
                        <CardParticipantSection
                            participantData={sideOneData}
                            matchData={matchData || matchDetails}
                            matchPenalties={matchPenalties}
                        />
                        <CardResultSection
                            resultData={sideOneData?.result}
                            oppositeSideResult={sideTwoData?.result}
                        />
                        <CardResultSection
                            resultData={sideTwoData?.result}
                            oppositeSideResult={sideOneData?.result}
                        />
                        <CardParticipantSection
                            participantData={sideTwoData}
                            matchData={matchData || matchDetails}
                            matchPenalties={matchPenalties}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-center px-0">
                        {sideOneData?.participant_uuid &&
                        sideTwoData?.participant_uuid ? (
                            <Row className="px-0 w-100">
                                <Col className="d-flex justify-content-between px-0 w-100">
                                    {sideOneData?.participant_uuid !== '-1' &&
                                        sideTwoData?.participant_uuid !==
                                            '-1' && (
                                            <>
                                                <Button
                                                    disabled={
                                                        user?.role?.type ===
                                                        'competitor'
                                                            ? true
                                                            : false
                                                    }
                                                    type="text"
                                                    className="w-100 tournament-match-card-buttons"
                                                    onClick={() =>
                                                        setShowChangeMatchResultModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Rezultat
                                                </Button>

                                                <Button
                                                    type="text"
                                                    className={`w-100 tournament-match-card-buttons ${
                                                        isDeleteEnabled
                                                            ? 'middle-button'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        setShowMatchPenaltiesModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Kazne
                                                </Button>
                                            </>
                                        )}

                                    {isDeleteEnabled && (
                                        <Button
                                            disabled={
                                                user?.role?.type ===
                                                'competitor'
                                                    ? true
                                                    : false
                                            }
                                            type="text"
                                            className={`w-100 tournament-match-card-buttons ${
                                                sideOneData?.participant_uuid !==
                                                    '-1' &&
                                                sideTwoData?.participant_uuid !==
                                                    '-1'
                                                    ? ''
                                                    : 'single-button'
                                            }`}
                                            onClick={() =>
                                                setShowDeleteMatchModal(true)
                                            }
                                        >
                                            Obriši
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <Row className="px-0 w-100">
                                <Col className="d-flex justify-content-between px-0 w-100">
                                    <Button
                                        disabled={
                                            user?.role?.type === 'competitor'
                                                ? true
                                                : false
                                        }
                                        type="text"
                                        className={`w-100 tournament-match-card-buttons ${
                                            isDeleteEnabled &&
                                            (sideOneData?.participant_uuid ||
                                                sideTwoData?.participant_uuid)
                                                ? ''
                                                : 'single-button'
                                        }`}
                                        onClick={() =>
                                            setShowCreateMatchModal(true)
                                        }
                                    >
                                        Dodaj takmičare
                                    </Button>

                                    {isDeleteEnabled &&
                                        (sideOneData?.participant_uuid ||
                                            sideTwoData?.participant_uuid) && (
                                            <Button
                                                disabled={
                                                    user?.role?.type ===
                                                    'competitor'
                                                        ? true
                                                        : false
                                                }
                                                type="text"
                                                className="w-100 tournament-match-card-buttons"
                                                onClick={() =>
                                                    setShowDeleteMatchModal(
                                                        true
                                                    )
                                                }
                                            >
                                                Obriši
                                            </Button>
                                        )}
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Col>

            <CreateMatchModal
                show={showCreateMatchModal}
                onHide={() => setShowCreateMatchModal(false)}
                matchDetails={{
                    sideOneData: sideOneData,
                    sideTwoData: sideTwoData,
                    ...matchData,
                    ...matchDetails,
                }}
                handleGetSingleMatch={handleGetSingleMatch}
                createNextMatch={createNextMatch}
                handleShowSlider={handleShowSlider}
            />

            <ChangeMatchResultModal
                show={showChangeMatchResultModal}
                onHide={() => setShowChangeMatchResultModal(false)}
                matchDetails={{
                    sideOneData: sideOneData,
                    sideTwoData: sideTwoData,
                    ...matchData,
                }}
                matchPenalties={matchPenalties}
                handleGetSingleMatch={handleGetSingleMatch}
                onChange={onChange}
                createNextMatch={createNextMatch}
                handleShowSlider={handleShowSlider}
                isFinal={isFinal}
            />

            <DeleteMatchModal
                show={showDeleteMatchModal}
                onHide={() => setShowDeleteMatchModal(false)}
                matchUuid={matchData?.uuid}
                handleGetSingleMatch={handleGetSingleMatch}
                onDelete={onDelete}
            />

            <MatchPenaltiesModal
                show={showMatchPenaltiesModal}
                onHide={() => setShowMatchPenaltiesModal(false)}
                sideOneData={sideOneData}
                sideTwoData={sideTwoData}
                matchUuid={matchData?.uuid}
                matchPenalties={matchPenalties}
                handleGetSingleMatch={handleGetSingleMatch}
            />
        </Row>
    )
}

export default TournamentMatchCard

import jsPDF from 'jspdf'
import {
    getSingleReferee,
    getTournamentsReferees,
} from '../../../../../../../lib/api'
import { formatDate } from '../../../../../../../lib/helpers'
import { font } from '../../../font'

const fetchTournamentAdminReferee = async (tournamentUuid) => {
    const refereesResponse = await getTournamentsReferees(tournamentUuid, {
        admin_referee: true,
    })

    if (refereesResponse && refereesResponse?.length > 0) {
        let refereeInfo = await getSingleReferee(
            refereesResponse[0]?.attributes?.referee_uuid
        )

        if (!refereeInfo) return null
        return refereeInfo
    } else {
        return null
    }
}

const addFooters = (doc, marginLeft) => {
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(
            `Datum i vreme štampanja: ${formatDate(
                new Date()
            )} ${new Date().toLocaleTimeString()}`,
            doc.internal.pageSize.width - marginLeft,
            doc.internal.pageSize.height - 10,
            { align: 'right' }
        )
    }
}
const createDoc = async (data, orientation, unit, size, font) => {
    const { competitionProfileData, sgpDataForPDF } = data
    const { groupMatches, winnersList } = sgpDataForPDF

    let adminRefereeInfo = await fetchTournamentAdminReferee(
        competitionProfileData?.attributes?.uuid
    )

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(15)

    // Za svaki datum
    for (let i = 0; i < groupMatches?.length; i++) {
        const matchesByField = groupMatches[i]

        // Postavljanje zaglavlja tablice za taj datum
        // doc.text(`Datum: ${matchesByField[0][0].attributes.match_date}`, 10, 10);

        const title = `${competitionProfileData?.attributes?.name}`
        doc.text(title, marginLeft, 40)

        const textWidth = doc.getTextDimensions(
            `Kolo: ${matchesByField?.round_number}`,
            { fontSize: 15 }
        ).w
        const pageWidth = doc.internal.pageSize.getWidth()
        const textX = pageWidth - textWidth - marginLeft
        doc.text(`Kolo: ${matchesByField?.round_number}`, textX, 40)

        let generalInfo = [
            [
                formatDate(competitionProfileData?.attributes?.start_date) ??
                    'Nema informacije',
                competitionProfileData?.attributes?.club_organizer?.attributes
                    ?.name ?? 'Nema informacije',
                competitionProfileData?.attributes?.tournament_category
                    ?.tournament_category_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.tournament_category?.tournament_category_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.tournament_category
                              ?.tournament_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                competitionProfileData?.attributes?.players_competition
                    ?.players_competition_name
                    ? `${
                          competitionProfileData?.attributes
                              ?.players_competition?.players_competition_name ??
                          ''
                      } ${
                          competitionProfileData?.attributes
                              ?.players_competition
                              ?.players_competition_subcategory_name ?? ''
                      }`
                    : 'Nema informacije',
                adminRefereeInfo
                    ? `${
                          adminRefereeInfo?.attributes?.person_info
                              ?.given_name ?? ''
                      } ${
                          adminRefereeInfo?.attributes?.person_info
                              ?.family_name ?? ''
                      }`
                    : 'Nema informacije',
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [
                [
                    'Datum',
                    'Grad, klub',
                    'Kategorija',
                    'Konkurencija',
                    'Vrhovni sudija',
                ],
            ],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(generalInfoContent)

        const tableData = []

        // Za svaki meč
        for (let k = 0; k < matchesByField?.matches?.length; k++) {
            const rowData = [`${k + 1}.`, '', '', '']

            const match = matchesByField?.matches[k]

            rowData[1] = `${
                match?.attributes?.sideOneData?.participant_uuid === '-1'
                    ? 'BYE'
                    : match?.attributes?.sideOneData?.player_1?.attributes
                          ?.person_info?.given_name +
                      ' ' +
                      match?.attributes?.sideOneData?.player_1?.attributes
                          ?.person_info?.family_name
            }${
                match?.attributes?.sideOneData?.player_2?.attributes
                    ?.person_info?.given_name
                    ? '\n' +
                      match?.attributes?.sideOneData?.player_2?.attributes
                          ?.person_info?.given_name +
                      ' ' +
                      match?.attributes?.sideOneData?.player_2?.attributes
                          ?.person_info?.family_name
                    : ''
            }\nvs\n${
                match?.attributes?.sideTwoData?.participant_uuid === '-1'
                    ? 'BYE'
                    : match?.attributes?.sideTwoData?.player_1?.attributes
                          ?.person_info?.given_name +
                      ' ' +
                      match?.attributes?.sideTwoData?.player_1?.attributes
                          ?.person_info?.family_name
            }${
                match?.attributes?.sideTwoData?.player_2?.attributes
                    ?.person_info?.given_name
                    ? '\n' +
                      match?.attributes?.sideTwoData?.player_2?.attributes
                          ?.person_info?.given_name +
                      ' ' +
                      match?.attributes?.sideTwoData?.player_2?.attributes
                          ?.person_info?.family_name
                    : ''
            }`
            rowData[2] = match?.attributes?.sideOneData?.result?.result
                ?.map(
                    (res, index) =>
                        `${res}-${match?.attributes?.sideTwoData?.result?.result?.[index]}`
                )
                .join(', ')
            rowData[3] = match?.attributes?.loser_participant_uuid
                ? match?.attributes?.loser_participant_uuid === '-1'
                    ? `${
                          match?.attributes?.sideOneData?.player_1?.attributes
                              ?.person_info?.given_name +
                          ' ' +
                          match?.attributes?.sideOneData?.player_1?.attributes
                              ?.person_info?.family_name
                      }`
                    : match?.attributes?.loser_participant_uuid ===
                      match?.attributes?.sideOneData?.participant_uuid
                    ? `${
                          match?.attributes?.sideTwoData?.player_1?.attributes
                              ?.person_info?.given_name +
                          ' ' +
                          match?.attributes?.sideTwoData?.player_1?.attributes
                              ?.person_info?.family_name
                      }`
                    : `${
                          match?.attributes?.sideOneData?.player_1?.attributes
                              ?.person_info?.given_name +
                          ' ' +
                          match?.attributes?.sideOneData?.player_1?.attributes
                              ?.person_info?.family_name
                      }`
                : ''
            // }
            // }

            // Dodavanje reda u tabelu
            tableData.push(rowData)
        }

        // Dodavanje tablice mečeva za taj datum
        doc.autoTable({
            head: [['', 'Meč', 'Rezultat', 'Pobednik']],
            body: tableData,
            startY: doc.lastAutoTable.finalY,
            styles: {
                font: 'WorkSans',
                halign: 'center',
                fontSize: 7,
            },
            headStyles: {
                lineWidth: 0.2,
                valign: 'middle',
            },
            columnStyles: {
                0: { cellWidth: 50, valign: 'middle', lineWidth: 0.2 },
                ...[...Array(3).keys()].reduce((acc, index) => {
                    acc[index + 1] = {
                        cellWidth:
                            (doc.internal.pageSize.width -
                                50 -
                                marginLeft -
                                marginLeft) /
                            3,
                        lineWidth: 0.2,
                        valign: 'middle',
                    }
                    return acc
                }, {}),
            },
            rowPageBreak: 'avoid',
        })

        // Dodavanje nove stranice za svaki novi datum, osim za posljednji datum
        // if (i !== groupMatches.length - 1) {
        doc.addPage()
        // }
    }

    const title = `${competitionProfileData?.attributes?.name}`
    doc.text(title, marginLeft, 40)

    const textWidth = doc.getTextDimensions(`Pobednik`, { fontSize: 15 }).w
    const pageWidth = doc.internal.pageSize.getWidth()
    const textX = pageWidth - textWidth - marginLeft
    doc.text(`Pobednik`, textX, 40)

    let generalInfo = [
        [
            formatDate(competitionProfileData?.attributes?.start_date) ??
                'Nema informacije',
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.name ?? 'Nema informacije',
            competitionProfileData?.attributes?.tournament_category
                ?.tournament_category_name
                ? `${
                      competitionProfileData?.attributes?.tournament_category
                          ?.tournament_category_name ?? ''
                  } ${
                      competitionProfileData?.attributes?.tournament_category
                          ?.tournament_subcategory_name ?? ''
                  }`
                : 'Nema informacije',
            competitionProfileData?.attributes?.players_competition
                ?.players_competition_name
                ? `${
                      competitionProfileData?.attributes?.players_competition
                          ?.players_competition_name ?? ''
                  } ${
                      competitionProfileData?.attributes?.players_competition
                          ?.players_competition_subcategory_name ?? ''
                  }`
                : 'Nema informacije',
            adminRefereeInfo
                ? `${
                      adminRefereeInfo?.attributes?.person_info?.given_name ??
                      ''
                  } ${
                      adminRefereeInfo?.attributes?.person_info?.family_name ??
                      ''
                  }`
                : 'Nema informacije',
        ],
    ]

    let generalInfoContent = {
        startY: 50,
        head: [
            [
                'Datum',
                'Grad, klub',
                'Kategorija',
                'Konkurencija',
                'Vrhovni sudija',
            ],
        ],
        body: generalInfo,
        styles: {
            font: 'WorkSans',
        },
    }

    doc.autoTable(generalInfoContent)

    let winnersListInfo = []

    winnersList?.map((participant) => {
        winnersListInfo.push([
            participant?.attributes?.tournament_rank,
            `${
                participant?.attributes?.player_1?.person_info?.given_name || ''
            } ${
                participant?.attributes?.player_1?.person_info?.family_name ||
                ''
            }`,
            participant?.attributes?.player_1?.person_info?.birthdate
                ? formatDate(
                      participant?.attributes?.player_1?.person_info?.birthdate
                  )
                : '-',
            participant?.attributes?.number_of_wins,
            participant?.attributes?.grand_prix_coefficient,
        ])
    })

    let winnersInfoContent = {
        startY: doc.lastAutoTable.finalY,
        head: [
            [
                'Pozicija',
                'Takmičar',
                'Datum rođenja',
                'Broj pobeda',
                'Koeficijent',
            ],
        ],
        body: winnersListInfo,
        styles: {
            font: 'WorkSans',
        },
        rowPageBreak: 'avoid',
    }

    doc.autoTable(winnersInfoContent)

    addFooters(doc, marginLeft)

    return doc
}

export const handleExportDrawSerbianGranPriPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    doc.save(`${data?.competitionProfileData?.attributes?.name}-ŽREB.pdf`)
}

export const handlePrintDrawSerbianGranPriPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}

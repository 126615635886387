import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NoPlayerImg from '../../assets/images/tennis-player-with-racket.png'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import './styles.scss'

const Player = ({ data }) => {
    return data?.map(
        (item, index) =>
            item?.uuid && (
                <div
                    className="py-2 d-flex align-items-center w-100"
                    key={index}
                >
                    <div className="d-flex align-items-center">
                        <Avatar
                            img={item?.picture || NoPlayerImg}
                            alt="fotografija igraca"
                            className="me-2"
                        />

                        <div className="d-flex align-items-center justify-content-between w-100">
                            <Link to={`/takmicari/${item?.uuid}`}>
                                {item?.name}
                            </Link>
                        </div>
                    </div>
                </div>
            )
    )
}

const CardParticipantSection = ({ participantData, firstPart = true }) => {
    const createPlayerObject = (player) => {
        return player
            ? {
                  name: `${player?.attributes?.person_info?.given_name || ''} ${
                      player?.attributes?.person_info?.family_name || ''
                  }`,
                  picture: player?.attributes?.person_info?.picture || '',
                  uuid: player?.attributes?.uuid || '',
              }
            : null
    }

    const playersArray = []
    if (!participantData) return

    if (participantData?.length > 1) {
        const firstMatch = participantData[0]
        const secondMatch = participantData[1]
        if (firstPart) {
            const side1Array = [
                createPlayerObject(firstMatch?.side_1_player_1),
                createPlayerObject(firstMatch?.side_1_player_2),
            ].filter(Boolean)

            const side2Array = [
                createPlayerObject(firstMatch?.side_2_player_1),
                createPlayerObject(firstMatch?.side_2_player_2),
            ].filter(Boolean)

            playersArray.push([[...side1Array], [...side2Array]])
        } else {
            const side1Array = [
                createPlayerObject(secondMatch?.side_1_player_1),
                createPlayerObject(secondMatch?.side_1_player_2),
            ].filter(Boolean)

            const side2Array = [
                createPlayerObject(secondMatch?.side_2_player_1),
                createPlayerObject(secondMatch?.side_2_player_2),
            ].filter(Boolean)

            playersArray.push([[...side1Array], [...side2Array]])
        }
    } else {
        const players = participantData[0]
        if (firstPart) {
            const side1Array = []

            if (players?.side_1_player_1) {
                side1Array.push({
                    name:
                        players?.side_1_player_1?.attributes?.person_info
                            ?.given_name +
                        ' ' +
                        players?.side_1_player_1?.attributes?.person_info
                            ?.family_name,
                    picture:
                        players?.side_1_player_1?.attributes?.person_info
                            ?.picture,
                    uuid: players?.side_1_player_1?.attributes?.uuid,
                })
            }

            if (players?.side_1_player_2) {
                side1Array.push({
                    name:
                        players?.side_1_player_2?.attributes?.person_info
                            ?.given_name +
                        ' ' +
                        players?.side_1_player_2?.attributes?.person_info
                            ?.family_name,
                    picture:
                        players?.side_1_player_2?.attributes?.person_info
                            ?.picture,
                    uuid: players?.side_1_player_2?.attributes?.uuid,
                })
            }
            playersArray.push([[...side1Array]])
        } else {
            const side1Array = []

            if (players?.side_2_player_1) {
                side1Array.push({
                    name:
                        players?.side_2_player_1?.attributes?.person_info
                            ?.given_name +
                        ' ' +
                        players?.side_2_player_1?.attributes?.person_info
                            ?.family_name,
                    picture:
                        players?.side_2_player_1?.attributes?.person_info
                            ?.picture,
                    uuid: players?.side_2_player_1?.attributes?.uuid,
                })
            }

            if (players?.side_2_player_2) {
                side1Array.push({
                    name:
                        players?.side_2_player_2?.attributes?.person_info
                            ?.given_name +
                        ' ' +
                        players?.side_2_player_2?.attributes?.person_info
                            ?.family_name,
                    picture:
                        players?.side_2_player_2?.attributes?.person_info
                            ?.picture,
                    uuid: players?.side_2_player_2?.attributes?.uuid,
                })
            }
            playersArray.push([[...side1Array]])
        }
    }

    return (
        <Row className={`tournament-match-card-row flex-column`}>
            {playersArray.map((players, playersArrayIndex) => (
                <div key={playersArrayIndex}>
                    {players.map((team, index) => (
                        <div key={index}>
                            {index === 1 && players[0][0] && players[1][0] ? (
                                <p className="text-center">ili</p>
                            ) : null}
                            <Player data={team} />
                        </div>
                    ))}
                </div>
            ))}
        </Row>
    )
}

export default CardParticipantSection

import React, { useEffect, useState } from 'react'
import {
    getClubMatchParticipants,
    getSingleClubMatch,
    getSingleClubPrevMatch,
    getSingleClubWithQuery,
} from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import './styles.scss'
const TournamentClubMatchCardForPDF = ({
    className,
    style,
    matchDetails,
    is_winner = false,
    competitionProfileData,
    show = false,
}) => {
    const { setIsLoading } = useAppContext()
    const side_1_top = matchDetails?.is_double ? 40 : 20
    const side_2_bottom = matchDetails?.is_double ? -20 : -20

    const [sideOneData, setSideOneData] = useState()
    const [sideTwoData, setSideTwoData] = useState()
    const [matchData, setMatchData] = useState()
    const [mounted, setMounted] = useState(false)

    const fetchParticipantsAndClubs = async (side_uuid) => {
        if (side_uuid && side_uuid !== '-1') {
            const participant = await getClubMatchParticipants(side_uuid)
            const club = await getSingleClubWithQuery(
                participant?.attributes?.club_uuid,
                'fields[0]=name&fields[1]=short_name&fields[2]=uuid&populate[logo][populate]'
            )
            return { participant, club }
        } else {
            return { participant: null, club: null }
        }
    }

    const handleGetSingleMatch = async () => {
        const { round_number, tournament_uuid, match } = matchDetails || {}

        try {
            if (round_number && tournament_uuid) {
                let match
                let side1PrevMatch
                let side2PrevMatch
                if (matchDetails?.match?.attributes?.uuid) {
                    match = matchDetails?.match
                } else {
                    match = await getSingleClubMatch(matchDetails)
                }

                if (!match) {
                    setSideOneData(null)
                    setSideTwoData(null)
                    setMatchData({ ...matchDetails })
                    return
                }

                const {
                    side_1_uuid,
                    side_2_uuid,
                    uuid,
                    loser_participant_uuid,
                    side_1_result,
                    side_2_result,
                    match_host_uuid,
                } = match?.attributes

                if (matchDetails?.round_number > 1) {
                    side1PrevMatch = await getSingleClubPrevMatch({
                        ...matchDetails,
                        round_number: matchDetails?.round_number - 1,
                        participant_uuid: side_1_uuid,
                    })

                    side2PrevMatch = await getSingleClubPrevMatch({
                        ...matchDetails,
                        round_number: matchDetails?.round_number - 1,
                        participant_uuid: side_2_uuid,
                    })
                }

                const [sideOne, sideTwo] = await Promise.all([
                    fetchParticipantsAndClubs(side_1_uuid),
                    fetchParticipantsAndClubs(side_2_uuid),
                ])

                let side1Result
                let side1LoserResult
                let side2Result
                let side2LoserResult

                if (is_winner) {
                    if (side_1_uuid !== loser_participant_uuid) {
                        side1Result = side_1_result
                        side1LoserResult = side_2_result
                    } else {
                        side2Result = side_2_result
                        side2LoserResult = side_1_result
                    }
                } else {
                    if (
                        side1PrevMatch?.attributes?.side_1_uuid === side_1_uuid
                    ) {
                        if (
                            side1PrevMatch?.attributes?.side_1_uuid ===
                            side1PrevMatch?.attributes?.loser_participant_uuid
                        ) {
                            side1Result =
                                side1PrevMatch?.attributes?.side_2_result

                            side1LoserResult =
                                side1PrevMatch?.attributes?.side_1_result
                        } else {
                            side1Result =
                                side1PrevMatch?.attributes?.side_1_result

                            side1LoserResult =
                                side1PrevMatch?.attributes?.side_2_result
                        }
                    } else {
                        if (
                            side1PrevMatch?.attributes?.side_2_uuid ===
                            side1PrevMatch?.attributes?.loser_participant_uuid
                        ) {
                            side1Result =
                                side1PrevMatch?.attributes?.side_1_result

                            side1LoserResult =
                                side1PrevMatch?.attributes?.side_2_result
                        } else {
                            side1Result =
                                side1PrevMatch?.attributes?.side_2_result

                            side1LoserResult =
                                side1PrevMatch?.attributes?.side_1_result
                        }
                    }

                    if (
                        side2PrevMatch?.attributes?.side_1_uuid === side_2_uuid
                    ) {
                        if (
                            side2PrevMatch?.attributes?.side_1_uuid ===
                            side2PrevMatch?.attributes?.loser_participant_uuid
                        ) {
                            side2Result =
                                side2PrevMatch?.attributes?.side_2_result

                            side2LoserResult =
                                side2PrevMatch?.attributes?.side_1_result
                        } else {
                            side2Result =
                                side2PrevMatch?.attributes?.side_1_result

                            side2LoserResult =
                                side2PrevMatch?.attributes?.side_2_result
                        }
                    } else {
                        if (
                            side2PrevMatch?.attributes?.side_2_uuid ===
                            side2PrevMatch?.attributes?.loser_participant_uuid
                        ) {
                            side2Result =
                                side2PrevMatch?.attributes?.side_1_result

                            side2LoserResult =
                                side2PrevMatch?.attributes?.side_2_result
                        } else {
                            side2Result =
                                side2PrevMatch?.attributes?.side_2_result

                            side2LoserResult =
                                side2PrevMatch?.attributes?.side_1_result
                        }
                    }
                }

                setSideOneData({
                    participant_uuid: side_1_uuid,
                    club: sideOne.club,
                    result: side1Result,
                    loser_result: side1LoserResult,
                })
                setSideTwoData({
                    participant_uuid: side_2_uuid,
                    club: sideTwo.club,
                    result: side2Result,
                    loser_result: side2LoserResult,
                })

                setMatchData({
                    ...matchDetails,
                    loser_participant_uuid,
                    single_club_match_uuid: uuid,
                    match_host_uuid,
                })
            }
            if (is_winner && show && mounted) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500)
            }
        } catch (error) {
            console.error('Greška:', error)
            if (is_winner && show && mounted) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500)
            }
        }
    }

    const handleShowResult = (result, loserResult) => {
        if (result) {
            return `${result}-${loserResult}`
        } else {
            return ''
        }
    }

    useEffect(() => {
        handleGetSingleMatch()
    }, [matchDetails])

    useEffect(() => {
        if (!mounted) {
            setMounted(true)
        }
    }, [])

    useEffect(() => {
        if (mounted) {
            if (is_winner && show) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500)
            }
        }
    }, [mounted])

    return is_winner ? (
        <>
            <div className="winner-line">
                <p className="ms-2">
                    {matchData?.loser_participant_uuid
                        ? matchData?.loser_participant_uuid ===
                          sideOneData?.participant_uuid
                            ? `${
                                  sideTwoData?.club?.attributes?.name
                                      ? sideTwoData?.club?.attributes?.name
                                      : ''
                              }${
                                  sideTwoData?.club?.attributes?.short_name
                                      ? ' ' +
                                        '(' +
                                        sideTwoData?.club?.attributes
                                            ?.short_name +
                                        ')'
                                      : ''
                              }`
                            : `${
                                  sideOneData?.club?.attributes?.name
                                      ? sideOneData?.club?.attributes?.name
                                      : ''
                              }${
                                  sideOneData?.club?.attributes?.short_name
                                      ? ' ' +
                                        '(' +
                                        sideOneData?.club?.attributes
                                            ?.short_name +
                                        ')'
                                      : ''
                              }`
                        : ''}
                </p>
            </div>
            <p className="ms-2">
                {handleShowResult(
                    matchData?.loser_participant_uuid ===
                        sideOneData?.participant_uuid
                        ? sideTwoData?.result
                        : sideOneData?.result,
                    matchData?.loser_participant_uuid ===
                        sideOneData?.participant_uuid
                        ? sideTwoData?.loser_result
                        : sideOneData?.loser_result
                ) || <span style={{ opacity: '0' }}>-</span>}
            </p>
        </>
    ) : (
        <div
            className={`tournament-match-card-for-pdf ${className}`}
            style={style}
        >
            <div className="side-1" style={{ top: `-${side_1_top}px` }}>
                <div className="d-flex align-items-center">
                    <p className="me-2">
                        {matchDetails?.round_number === 1 &&
                            matchDetails?.pair_number -
                                1 +
                                matchDetails?.pair_number}
                    </p>

                    <div>
                        <p>
                            {sideOneData?.participant_uuid === '-1'
                                ? 'BYE'
                                : `${
                                      sideOneData?.club?.attributes?.name
                                          ? sideOneData?.club?.attributes?.name
                                          : ''
                                  }${
                                      sideOneData?.club?.attributes?.short_name
                                          ? ' ' +
                                            '(' +
                                            sideOneData?.club?.attributes
                                                ?.short_name +
                                            ')'
                                          : ''
                                  }`}
                        </p>
                    </div>
                </div>
                <p className="ms-2">
                    {handleShowResult(
                        sideOneData?.result,
                        sideOneData?.loser_result
                    ) || <span style={{ opacity: '0' }}>-</span>}
                </p>
            </div>
            <div className="side-2" style={{ bottom: `${side_2_bottom}px` }}>
                <div className="d-flex align-items-center">
                    <p className="me-2">
                        {matchDetails?.round_number === 1 &&
                            matchDetails?.pair_number * 2}
                    </p>
                    <div>
                        <p>
                            {sideTwoData?.participant_uuid === '-1'
                                ? 'BYE'
                                : `${
                                      sideTwoData?.club?.attributes?.name
                                          ? sideTwoData?.club?.attributes?.name
                                          : ''
                                  }${
                                      sideTwoData?.club?.attributes?.short_name
                                          ? ' ' +
                                            '(' +
                                            sideTwoData?.club?.attributes
                                                ?.short_name +
                                            ')'
                                          : ''
                                  }`}
                        </p>
                    </div>
                </div>

                <p className="ms-2">
                    {handleShowResult(
                        sideTwoData?.result,
                        sideTwoData?.loser_result
                    ) || <span style={{ opacity: '0' }}>-</span>}
                </p>
            </div>
        </div>
    )
}

export default TournamentClubMatchCardForPDF

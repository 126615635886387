import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Add from '../../../assets/svg/Add'
import CloseOutlined from '../../../assets/svg/CloseOutlined'
import DocumentTextOutlined from '../../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../../assets/svg/PrintOutlined'
import Button from '../../../components/Button'
import ConfirmationModal from '../../../components/ConfirmationModal'
import NoDataForView from '../../../components/NoDataForView'
import TextField from '../../../components/TextField'
import {
    addMembership,
    getMemberships,
    getUserByEmail,
    removeMembership,
} from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import { formatDate, isArrayEmpty } from '../../../lib/helpers'
import ScrollToTop from '../../../lib/hooks/ScrollToTop'
import { font } from '../../Admin/Competitions/CompetitionProfile/font'
import MembershipCard from './MembershipCard'
import './styles.scss'

function Membership({ profileData, clubId, setShowMembership, club = false }) {
    const { id } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [membershipIndexUpdate, setMembershipIndexUpdate] = useState(-1)
    //const [membershipForUpdate, setMembershipForUpdate] = useState()
    const [memberships, setMemberships] = useState([])
    const [disabledSave, setDisabledSave] = useState(true)
    const [newMembership, setNewMembership] = useState({
        date_of_payment: '',
        member_uuid: profileData
            ? profileData?.attributes?.uuid
            : club?.attributes?.uuid,
        is_club: club ? true : false,
    })
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const { user, setShowCustomToast } = useAppContext()

    const checkIfUserExists = async (email) => {
        const userExist = await getUserByEmail(email)

        if (!userExist?.length) return false

        return true
    }

    const handleAddMembership = async () => {
        try {
            const membership = await addMembership(newMembership)
            if (membership) {
                const yearOfPayment = new Date(
                    membership?.attributes?.date_of_payment
                )?.getFullYear()
                handleGetMemberships()
                handleModalClose()
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Članarina uspešno dodata.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handlePdfExport = (print = false) => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Članarina${
            club
                ? ' - ' +
                  club?.attributes?.name +
                  ' - ' +
                  formatDate(new Date())
                : ' - ' +
                  profileData?.attributes?.first_name +
                  ' ' +
                  profileData?.attributes?.last_name +
                  ' - ' +
                  formatDate(new Date())
        }`
        doc.text(title, marginLeft, 40)

        const rows = []

        memberships?.forEach((membership) => {
            let data = membership?.attributes?.date_of_payment
                ? formatDate(membership?.attributes?.date_of_payment)
                : '-'

            rows.push([data])
        })

        let positionContent = {
            startY: 50,
            head: [['Datum poslednje registracije']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(positionContent)

        if (print) {
            doc.autoPrint()
            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(
                `Članarina-${
                    club
                        ? club?.attributes?.name
                        : profileData?.attributes?.first_name +
                          ' ' +
                          profileData?.attributes?.last_name
                }-${formatDate(new Date()).slice(0, -1)}.pdf`
            )
        }
    }

    const handleGetMemberships = async () => {
        const memberships = await getMemberships(
            profileData ? profileData?.attributes?.uuid : club?.attributes?.uuid
        )
        if (memberships?.length > 0) {
            setMemberships(memberships)
        } else {
            setMemberships([])
        }
    }

    useEffect(() => {
        setNewMembership((_) => {
            return {
                date_of_payment: '',
                member_uuid: profileData
                    ? profileData?.attributes?.uuid
                    : club?.attributes?.uuid,
                is_club: club ? true : false,
            }
        })

        handleGetMemberships()
    }, [id, profileData, club])

    const handleRemoveMembership = async (membershipUuid) => {
        try {
            const removedMembership = await removeMembership(membershipUuid)

            if (removedMembership) {
                handleGetMemberships()
                setShowConfirmationModal((_) => {
                    return false
                })
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Članarina uspešno obrisana.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    useEffect(() => {
        if (membershipIndexUpdate < 0) {
            setNewMembership((_) => {
                return {
                    date_of_payment: '',
                    member_uuid: profileData
                        ? profileData?.attributes?.uuid
                        : club?.attributes?.uuid,
                    is_club: club ? true : false,
                }
            })
        }
    }, [membershipIndexUpdate])

    const handleModalClose = () => {
        setOpenModal(false)

        // This line of code resets the fields in modal, never mind if it is updating or adding
        setMembershipIndexUpdate((prev) => {
            return prev < 0 ? prev - 1 : -1
        })
    }

    useEffect(() => {
        handleRequiredFields()
    }, [newMembership])

    const handleRequiredFields = () => {
        if (newMembership?.date_of_payment) {
            setDisabledSave(false)
        } else {
            setDisabledSave(true)
        }
    }

    return (
        <Row className="membership-view">
            <ScrollToTop />
            <Col className="membership-wrapper">
                <Row>
                    <Col className="d-flex align-items-center justify-content-end close-icon">
                        <CloseOutlined
                            onClick={() => setShowMembership(false)}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center flex-column">
                    <Col className="d-flex align-items-center justify-content-center membership-title">
                        <h1>Članarina</h1>
                    </Col>

                    {!isArrayEmpty(memberships) ? (
                        <Col
                            xs={12}
                            className="d-flex flex-column align-items-center justify-content-center align-self-center memberships-container"
                        >
                            <Row className="w-100">
                                {user?.role?.type === 'admin' && (
                                    <Col
                                        xs={12}
                                        className="add-membership-col d-md-flex d-none align-items-center justify-content-end py-1"
                                    >
                                        <Button
                                            type="text"
                                            leadingIcon={<Add />}
                                            onClick={() => setOpenModal(true)}
                                        >
                                            Dodaj
                                        </Button>
                                    </Col>
                                )}
                                <Col
                                    xs={12}
                                    className={`add-membership-col d-flex d-md-none align-items-center  ${
                                        user?.role?.type === 'admin'
                                            ? 'justify-content-between'
                                            : 'justify-content-around'
                                    } gx-2`}
                                >
                                    <Button
                                        type="text"
                                        onClick={() => handlePdfExport()}
                                    >
                                        <PrintOutlined height="24px" />
                                    </Button>
                                    <Button
                                        type="text"
                                        onClick={() => handlePdfExport(true)}
                                    >
                                        <DocumentTextOutlined height="24px" />
                                    </Button>
                                    {user?.role?.type === 'admin' && (
                                        <Button
                                            type="text"
                                            onClick={() => setOpenModal(true)}
                                        >
                                            <Add height="24px" />
                                        </Button>
                                    )}
                                </Col>
                                <Col
                                    xs={12}
                                    className={`d-md-flex d-none align-items-center justify-content-between single-membership`}
                                >
                                    <Col>
                                        <h5 className={`membership-header`}>
                                            Datum poslednje registracije
                                        </h5>
                                    </Col>

                                    {user?.role?.type === 'admin' && (
                                        <Col>
                                            <h5 className="membership-header"></h5>
                                        </Col>
                                    )}
                                </Col>
                            </Row>
                            <div className="membership-list">
                                {memberships?.map((membership, index) => (
                                    <Fragment key={index}>
                                        <Col
                                            className={`d-md-flex d-none align-items-center justify-content-between single-membership`}
                                        >
                                            <Col>
                                                <p
                                                    className={`membership-year`}
                                                >
                                                    {formatDate(
                                                        membership?.attributes
                                                            ?.date_of_payment
                                                    )}
                                                </p>
                                            </Col>
                                            {user?.role?.type === 'admin' && (
                                                <Col className="text-end">
                                                    <p
                                                        className="red-link"
                                                        onClick={() =>
                                                            setShowConfirmationModal(
                                                                (_) => {
                                                                    return membership
                                                                        ?.attributes
                                                                        ?.uuid
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Ukloni
                                                    </p>
                                                </Col>
                                            )}
                                        </Col>
                                        <MembershipCard
                                            membership={membership}
                                            isWhite={
                                                index % 2 !== 0 ? true : false
                                            }
                                            isAdmin={
                                                user?.role?.type === 'admin'
                                            }
                                            className="d-md-none"
                                            setShowConfirmationModal={
                                                setShowConfirmationModal
                                            }
                                        />
                                    </Fragment>
                                ))}
                            </div>
                            <Col className="d-md-flex d-none align-items-center justify-content-end membership-buttons-wrapper px-0">
                                <Button
                                    type="text"
                                    onClick={() => handlePdfExport()}
                                    className="me-2"
                                >
                                    Eksportuj u PDF
                                </Button>

                                <Button
                                    type="secondary"
                                    onClick={() => handlePdfExport(true)}
                                >
                                    Štampaj
                                </Button>
                            </Col>
                        </Col>
                    ) : (
                        <NoDataForView
                            title="Istorija članarina je prazna"
                            description={
                                user?.role?.type === 'admin'
                                    ? 'Dodajte članarinu klikom na dugme ispod.'
                                    : ''
                            }
                            buttonText={
                                user?.role?.type === 'admin' ? 'Dodaj' : null
                            }
                            onClick={() =>
                                user?.role?.type === 'admin'
                                    ? setOpenModal(true)
                                    : null
                            }
                            className="border-0"
                            column
                        />
                    )}
                </Row>
            </Col>

            <Modal
                size="md"
                centered
                show={openModal}
                onHide={handleModalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Dodaj članarinu</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        id="date_of_payment"
                        name="date_of_payment"
                        type="date"
                        label="Datum poslednje registracije"
                        value={newMembership?.date_of_payment}
                        onChange={(e) => {
                            setNewMembership({
                                ...newMembership,
                                date_of_payment: e.target.value,
                            })
                        }}
                        className="mb-3"
                        required
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={handleModalClose}>
                        Odustani
                    </Button>
                    <Button
                        onClick={handleAddMembership}
                        disabled={disabledSave}
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                modalText="Jeste li sigurni da želite obrisati članarinu?"
                onHide={() =>
                    setShowConfirmationModal((_) => {
                        return false
                    })
                }
                onClick={() => handleRemoveMembership(showConfirmationModal)}
            />
        </Row>
    )
}

export default Membership

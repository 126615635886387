import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AsyncSelect from '../../../../../../components/AsyncSelect'
import Button from '../../../../../../components/Button'
import Checkbox from '../../../../../../components/Checkbox'
import Dropdown from '../../../../../../components/Dropdown'
import TextField from '../../../../../../components/TextField'
import RadioButton from '../../../../../../components/RadioButton'
import {
    createSchedule,
    editSchedule,
    getSingleClub,
} from '../../../../../../lib/api'
import {
    convertDateFormatWithDay,
    formatTimeExport,
    getClubList,
    getListOfMatches,
    getListOfPotentialMatches,
    handleSchedulePrintText,
} from '../../../../../../lib/helpers'

const AddMatchModal = ({
    show,
    onHide,
    tournament,
    selectedFieldUuid,
    selectedScheduleDate,
    handleShowTournamentsField,
    editForm = false,
    players = false,
    schedules = false,
    clubOrganizer = false,
}) => {
    const today = new Date()
    const formattedDate = `${today.getFullYear()}-${String(
        today.getMonth() + 1
    ).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`

    const [scheduleForm, setScheduleForm] = useState(
        editForm
            ? editForm
            : {
                  match_date: formattedDate,
                  time_description: 'Sledi',
                  club_host_uuid: tournament?.attributes?.club_organizer_uuid,
              }
    )

    const [disabledAdding, setDisabledAdding] = useState(true)
    const [selectedClub, setSelectedClub] = useState(null)
    const [pairsRadio, setPairsRadio] = useState('Poznati parovi')
    const [genderRadio, setGenderRadio] = useState(
        tournament?.attributes?.genders[0].gender_name
    )
    const [inputValue, setInputValue] = useState('')

    const getMatchParticipantsWrapper = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getListOfMatches(inputValue, loadedOptions, paginationData, {
            tournament_uuid: tournament?.attributes?.uuid,
            not_finished: true,
            alreadySelectedMatches: [].concat(
                ...schedules.map((list) =>
                    list.map(
                        (item) =>
                            !item.attributes.second_match_uuid &&
                            item.attributes.match_uuid
                    )
                )
            ),
        })
    }

    const getPotentialMatchParticipantsWrapper = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getListOfPotentialMatches(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: tournament?.attributes?.uuid,
                not_finished: true,
                gender: genderRadio,
                alreadySelectedMatches: [].concat(
                    ...schedules.map((list) =>
                        list.map(
                            (item) =>
                                item.attributes.second_match_uuid &&
                                item.attributes.match_uuid
                        )
                    )
                ),
            }
        )
    }
    useEffect(() => {
        setPairsRadio('Poznati parovi')
        setGenderRadio(tournament?.attributes?.genders[0].gender_name)
        if (show) {
            if (!editForm) {
                const today = new Date()
                const formattedDate = `${today.getFullYear()}-${String(
                    today.getMonth() + 1
                ).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
                setScheduleForm((prev) => ({
                    ...prev,
                    match_date: prev?.match_date
                        ? prev.match_date
                        : formattedDate,
                    time_description: prev?.time_description
                        ? prev?.time_description
                        : 'Sledi',
                    club_host_uuid: tournament?.attributes?.club_organizer_uuid,
                }))
                setSelectedClub({
                    uuid: tournament?.attributes?.club_organizer_uuid,
                    value: tournament?.attributes?.club_organizer?.attributes
                        ?.name,
                })
            } else {
                const getClubInfo = async (clubUuid) => {
                    let club = await getSingleClub(clubUuid)
                    if (!club) return null
                    setSelectedClub({
                        uuid: club?.attributes?.uuid,
                        value: club?.attributes?.name,
                    })
                }
                if (editForm?.club_host_uuid) {
                    getClubInfo(editForm?.club_host_uuid)
                } else {
                    setSelectedClub({
                        uuid: clubOrganizer?.attributes?.uuid,
                        value: clubOrganizer?.attributes?.name,
                    })
                    setScheduleForm((prev) => ({
                        ...prev,
                        club_host_uuid: clubOrganizer?.attributes?.uuid,
                    }))
                }
            }
        }
    }, [show])

    const handleAddMatchToSchedule = async () => {
        if (editForm) {
            const { uuid, ...rest } = scheduleForm
            const newSchedule = await editSchedule(editForm?.uuid, {
                ...rest,
                club_host_uuid: selectedClub?.uuid,
            })

            if (newSchedule) {
                handleShowTournamentsField()
                setScheduleForm({})
                onHide()
            }
        } else {
            const newSchedule = await createSchedule({
                ...scheduleForm,
                field_uuid: selectedFieldUuid,
                match_date: convertDateFormatWithDay(selectedScheduleDate),
            })

            if (newSchedule) {
                handleShowTournamentsField()
                setScheduleForm({})
                onHide()
            }
        }
    }

    const handleClubSelect = (e) => {
        if (!e) {
            setScheduleForm((prev) => ({
                ...prev,
                club_host_uuid: tournament?.attributes?.club_organizer_uuid,
            }))
            setSelectedClub({
                uuid: tournament?.attributes?.club_organizer_uuid,
                value: tournament?.attributes?.club_organizer?.attributes?.name,
            })
        } else {
            const { uuid, value } = e

            setScheduleForm((prev) => ({ ...prev, club_host_uuid: uuid }))
            setSelectedClub((prev) => ({ uuid: uuid, value: value }))
        }
    }

    const handleInputLabel = () => {
        if (!players) return ''

        let value = handleSchedulePrintText(players)

        setInputValue(value || '')
    }

    useEffect(() => {
        if (scheduleForm?.match_uuid) {
            if (scheduleForm?.time_description) {
                switch (scheduleForm?.time_description) {
                    case 'Sledi':
                    case 'TBA':
                        if (scheduleForm?.match_time) {
                            setScheduleForm((prev) => ({
                                ...prev,
                                match_time: null,
                            }))
                        }
                        setDisabledAdding(false)
                        break
                    case 'Tačno vreme':
                    case 'Ne pre':
                    case 'Posle odmora':
                        if (scheduleForm?.match_time) {
                            setDisabledAdding(false)
                        } else {
                            setDisabledAdding(true)
                        }
                        break
                    default:
                        setDisabledAdding(true)
                        break
                }
            }
        } else {
            setDisabledAdding(true)
        }

        handleInputLabel()
    }, [scheduleForm])

    useEffect(() => {
        setScheduleForm(editForm)
    }, [editForm])

    return (
        <Modal
            centered
            show={show}
            onHide={() => {
                setScheduleForm({})
                onHide()
            }}
            dialogClassName="add-penalties-modal"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>
                        {editForm ? 'Izmena meča' : 'Dodavanje meča u raspored'}
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {editForm ? (
                    <TextField
                        label="Meč"
                        className="mb-3"
                        value={inputValue}
                        disabled={true}
                        required
                    />
                ) : (
                    <div>
                        <RadioButton
                            className="mb-3"
                            radioValues={[
                                'Poznati parovi',
                                'Potencijalni parovi',
                            ]}
                            onChange={(e) => {
                                setPairsRadio(e.target.value)
                            }}
                            checkedValue={pairsRadio}
                        />

                        {tournament?.attributes?.genders?.length > 1 &&
                            pairsRadio === 'Potencijalni parovi' && (
                                <RadioButton
                                    className="mb-3"
                                    radioValues={tournament?.attributes?.genders?.map(
                                        (gender) => {
                                            return gender?.gender_name
                                        }
                                    )}
                                    onChange={(e) => {
                                        setGenderRadio(e.target.value)
                                    }}
                                    checkedValue={genderRadio}
                                />
                            )}
                        <AsyncSelect
                            loadOptions={
                                pairsRadio === 'Poznati parovi'
                                    ? getMatchParticipantsWrapper
                                    : getPotentialMatchParticipantsWrapper
                            }
                            onChange={(e) =>
                                setScheduleForm((prev) => ({
                                    ...prev,
                                    match_uuid: e.first_match_uuid || null,
                                    second_match_uuid:
                                        e.second_match_uuid || null,
                                }))
                            }
                            label={'Meč'}
                            placeholder={'Izaberi meč...'}
                            noOptionsMessage={'Nema kreiranih mečeva'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                            required
                            cacheUniqs={[pairsRadio, genderRadio]}
                        />
                    </div>
                )}

                <Dropdown
                    label={'Vreme'}
                    placeholder={'Izaberi vreme'}
                    dropdownLinks={[
                        'Tačno vreme',
                        'Sledi',
                        'Ne pre',
                        'Posle odmora',
                        'TBA',
                    ]}
                    handleSelect={(e) =>
                        setScheduleForm((prev) => ({
                            ...prev,
                            time_description: e,
                        }))
                    }
                    value={scheduleForm?.time_description || ''}
                    className="my-3"
                    required
                />

                {scheduleForm?.time_description === 'Posle odmora' ? (
                    <TextField
                        label="Ne pre"
                        type="time"
                        className="mb-3"
                        onChange={(e) => {
                            setScheduleForm((prev) => ({
                                ...prev,
                                match_time: formatTimeExport(e?.target?.value),
                            }))
                        }}
                        value={scheduleForm?.match_time || ''}
                        required
                    />
                ) : (
                    (scheduleForm?.time_description === 'Tačno vreme' ||
                        scheduleForm?.time_description === 'Ne pre') && (
                        <TextField
                            label="Vreme"
                            type="time"
                            className="mb-3"
                            onChange={(e) => {
                                setScheduleForm((prev) => ({
                                    ...prev,
                                    match_time: formatTimeExport(
                                        e?.target?.value
                                    ),
                                }))
                            }}
                            value={scheduleForm?.match_time || ''}
                            required
                        />
                    )
                )}

                <AsyncSelect
                    loadOptions={getClubList}
                    onChange={handleClubSelect}
                    label={'Izaberi klub'}
                    value={selectedClub}
                    placeholder={'Pretraži i izaberi klub...'}
                    noOptionsMessage={'Traženi klub ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    isClearable={true}
                    defaultOptions={false}
                />

                <Checkbox
                    id="acitve"
                    onChange={() =>
                        setScheduleForm((prev) => ({
                            ...prev,
                            first_free_field: !prev?.first_free_field,
                        }))
                    }
                    checked={scheduleForm?.first_free_field || false}
                    label="Igra se na prvom slobodnom terenu"
                    className={'my-3'}
                />

                <TextField
                    label="Vreme završetka meča"
                    type="time"
                    onChange={(e) => {
                        setScheduleForm((prev) => ({
                            ...prev,
                            match_end_time: formatTimeExport(e?.target?.value),
                        }))
                    }}
                    value={scheduleForm?.match_end_time || ''}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="text"
                    onClick={() => {
                        setScheduleForm({})
                        onHide()
                    }}
                >
                    Zatvori
                </Button>
                <Button
                    onClick={() => handleAddMatchToSchedule()}
                    disabled={disabledAdding}
                >
                    {editForm ? 'Izmeni meč' : 'Dodaj meč'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddMatchModal

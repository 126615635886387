import React from 'react'
import { Table } from 'react-bootstrap'
import PaginationWrapper from '../Pagination'
import { Link } from 'react-router-dom'
import { generateAvatar } from '../../utils/generateAvatar'
import { formatDate, formatImgUrl } from '../../lib/helpers'
const SQPTable = ({
    participants,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className="table-competitors-pagination-wrapper table-competitors-wrapper competitions-participation-table">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '70px', minWidth: '70px' }}>
                            <h5>Pozicija</h5>
                        </th>
                        <th>
                            <h5>Takmičar</h5>
                        </th>
                        <th>
                            <h5>Klub</h5>
                        </th>
                        <th>
                            <h5>Datum rođenja</h5>
                        </th>
                        <th>
                            <h5>Broj pobeda</h5>
                        </th>
                        <th>
                            <h5>Koeficijent</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {participants?.map((participant, index) => (
                        <tr key={index}>
                            <td
                                className="align-middle first-column"
                                style={{ width: '70px', minWidth: '70px' }}
                            >
                                <p>
                                    {participant?.attributes?.tournament_rank}
                                </p>
                            </td>

                            <td style={{ minWidth: '140px' }}>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center">
                                        <img
                                            className="competitor-img me-2"
                                            src={
                                                participant?.attributes
                                                    ?.player_1?.person_info
                                                    ?.picture
                                                    ? participant?.attributes
                                                          ?.player_1
                                                          ?.person_info?.picture
                                                    : generateAvatar(
                                                          `${
                                                              participant
                                                                  ?.attributes
                                                                  ?.player_1
                                                                  ?.person_info
                                                                  ?.given_name ||
                                                              ''
                                                          } ${
                                                              participant
                                                                  ?.attributes
                                                                  ?.player_1
                                                                  ?.person_info
                                                                  ?.family_name ||
                                                              ''
                                                          }`
                                                      )
                                            }
                                        />
                                        <div>
                                            <Link
                                                to={`/takmicari/${participant?.attributes?.player_1?.uuid}`}
                                            >
                                                <p className="link">
                                                    {
                                                        participant?.attributes
                                                            ?.player_1
                                                            ?.person_info
                                                            ?.given_name
                                                    }{' '}
                                                    {
                                                        participant?.attributes
                                                            ?.player_1
                                                            ?.person_info
                                                            ?.family_name
                                                    }
                                                </p>
                                            </Link>
                                        </div>
                                    </div>

                                    {participant?.attributes?.player_2 && (
                                        <div className="d-flex align-items-center mt-2">
                                            <img
                                                className="competitor-img me-2"
                                                src={
                                                    participant?.attributes
                                                        ?.player_2?.person_info
                                                        ?.picture
                                                        ? participant
                                                              ?.attributes
                                                              ?.player_2
                                                              ?.person_info
                                                              ?.picture
                                                        : generateAvatar(
                                                              `${participant?.attributes?.player_2?.person_info?.given_name}} ${participant?.attributes?.player_2?.person_info?.family_name}`
                                                          )
                                                }
                                            />
                                            <div>
                                                <Link
                                                    to={`/takmicari/${participant?.attributes?.player_2?.uuid}`}
                                                >
                                                    <p className="link">
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_2
                                                                ?.person_info
                                                                ?.given_name
                                                        }{' '}
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_2
                                                                ?.person_info
                                                                ?.family_name
                                                        }
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </td>

                            <td
                                style={{ minWidth: '170px' }}
                                className="d-flex align-items-center justify-content-start"
                            >
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center">
                                        {participant?.attributes?.player_1_club
                                            ?.attributes?.name ? (
                                            <>
                                                <img
                                                    src={
                                                        participant?.attributes
                                                            ?.player_1_club
                                                            ?.attributes?.logo
                                                            ?.data
                                                            ? formatImgUrl(
                                                                  participant
                                                                      ?.attributes
                                                                      ?.player_1_club
                                                                      ?.attributes
                                                                      ?.logo
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.url
                                                              )
                                                            : generateAvatar(
                                                                  participant
                                                                      ?.attributes
                                                                      ?.player_1_club
                                                                      ?.attributes
                                                                      ?.name
                                                              )
                                                    }
                                                    alt={`Logo kluba`}
                                                    className="competitor-img"
                                                />
                                                <Link
                                                    to={`/klubovi/${participant?.attributes?.player_1_club?.attributes?.uuid}`}
                                                >
                                                    <p className="ms-2 link">
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_1_club
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                    </p>
                                                </Link>
                                            </>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </div>

                                    {participant?.attributes?.is_double ? (
                                        participant?.attributes?.player_2_club
                                            ?.attributes?.name ? (
                                            <div className="d-flex align-items-center mt-2">
                                                <img
                                                    src={
                                                        participant?.attributes
                                                            ?.player_2_club
                                                            ?.attributes?.logo
                                                            ?.data
                                                            ? formatImgUrl(
                                                                  participant
                                                                      ?.attributes
                                                                      ?.player_2_club
                                                                      ?.attributes
                                                                      ?.logo
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.url
                                                              )
                                                            : generateAvatar(
                                                                  participant
                                                                      ?.attributes
                                                                      ?.player_2_club
                                                                      ?.attributes
                                                                      ?.name
                                                              )
                                                    }
                                                    alt={`Logo kluba`}
                                                    className="competitor-img"
                                                />
                                                <Link
                                                    to={`/klubovi/${participant?.attributes?.player_2_club?.attributes?.uuid}`}
                                                >
                                                    <p className="ms-2 link">
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_2_club
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                    </p>
                                                </Link>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <p>-</p>
                                            </div>
                                        )
                                    ) : null}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column justify-content-center">
                                    <p className="p-columns">
                                        {participant?.attributes?.player_1
                                            ?.person_info?.birthdate
                                            ? formatDate(
                                                  participant?.attributes
                                                      ?.player_1?.person_info
                                                      ?.birthdate
                                              )
                                            : '-'}
                                    </p>
                                    {participant?.attributes?.player_2 && (
                                        <p className="p-columns mt-2">
                                            {participant?.attributes?.player_2
                                                ?.person_info?.birthdate
                                                ? formatDate(
                                                      participant?.attributes
                                                          ?.player_2
                                                          ?.person_info
                                                          ?.birthdate
                                                  )
                                                : '-'}
                                        </p>
                                    )}
                                </div>
                            </td>

                            <td>
                                <p>{participant?.attributes?.number_of_wins}</p>
                            </td>

                            <td>
                                <p>
                                    {
                                        participant?.attributes
                                            ?.grand_prix_coefficient
                                    }
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default SQPTable

import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Button from '../../../components/Button'
import PlayersMigrationCard from '../../../components/PlayersMigrationCard'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import TextField from '../../../components/TextField'
import { getPlayers } from '../../../lib/api'
import PlayersMigrationTable from './PlayersMigrationTable'

const PlayersMigration = () => {
    const [insertedName, setInsertedName] = useState('')
    const [insertedSurname, setInsertedSurname] = useState('')

    const [competitorsList, setCompetitorsList] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const getAllCompetitors = async (page, presetFilters) => {
        if (insertedName && insertedSurname) {
            let searchParams = {
                given_name: insertedName,
                family_name: insertedSurname,
            }

            let fields = []

            let { competitors, pgCount, total } = await getPlayers(
                fields,
                searchParams,
                {
                    email_is_null: true,
                },
                page,
                false,
                rowsPerPage
            )

            if (competitors) {
                setCompetitorsList(competitors)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
            }
        }
    }

    useEffect(() => {
        getAllCompetitors(1)
    }, [currentPage, rowsPerPage])

    return (
        <Row className="mt-3">
            <Col>
                <Row className="justify-content-center">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <TextField
                                            label="Ime (najmanje 2 karaktera)"
                                            required
                                            type="text"
                                            id="name"
                                            placeholder="Unesite vaše ime"
                                            value={insertedName}
                                            onChange={(e) =>
                                                setInsertedName(e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} className="mt-3">
                                        <TextField
                                            required
                                            label="Prezime (najmanje 2 karaktera)"
                                            type="text"
                                            id="name"
                                            placeholder="Unesite vaše prezime"
                                            value={insertedSurname}
                                            onChange={(e) =>
                                                setInsertedSurname(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            className="my-3"
                                            onClick={() => getAllCompetitors()}
                                            disabled={
                                                insertedName?.length >= 2 &&
                                                insertedSurname?.length >= 2
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Pretraži
                                        </Button>
                                    </Col>
                                </Row>

                                {competitorsList?.length > 0 ? (
                                    <Row className="mt-4">
                                        <Col className="d-none d-md-block">
                                            <PlayersMigrationTable
                                                data={competitorsList}
                                                status="active"
                                                className="d-none d-md-block"
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </Col>

                                        <Col className="d-block d-md-none">
                                            {competitorsList?.map(
                                                (competitor, index) => (
                                                    <PlayersMigrationCard
                                                        key={index}
                                                        competitor={competitor}
                                                    />
                                                )
                                            )}
                                        </Col>
                                    </Row>
                                ) : (
                                    total === 0 && (
                                        <Row>
                                            <Col>
                                                <p>
                                                    Nema rezultata ili je
                                                    takmičar već registrovan.
                                                </p>
                                            </Col>
                                        </Row>
                                    )
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PlayersMigration
